<template>
  <div class="wrap">
    <carouselBanner paddingTop="14.6%" :bannerType="4" />
    <div class="content_area">
      <searchCompany
        v-model="queryParams.companyName"
        @search="search"
        class="search-wrap"
      />
      <filterSearch @handleSearch="handleSearch" />
      <div class="main">
        <div class="left">
          <div>
            <unitItem
              @refresh="refresh"
              v-for="(item, index) in companyList"
              :number="2"
              :companyInfo="item"
              :key="index"
            />
          </div>

          <isLoadingTip v-if="loading" />
          <isAllDataTip
            v-if="
              companyList.length && total === companyList.length && !loading
            "
          />
          <noDataImg v-if="companyList.length === 0"></noDataImg>
          <div class="clearfix" v-show="companyList.length">
            <el-pagination
              class="fr"
              style="margin-top: 10px"
              @current-change="handleChange"
              :current-page="currentPage + 1"
              :total="total"
              background
              :page-size="10"
              layout="prev, pager, next"
            >
            </el-pagination>
          </div>
        </div>
        <div class="right">
          <!--广告位-->
          <companyAdvertisement />
          <!-- 新加入成员 -->
          <newPerson />
          <!-- 您的行业顾问已上线，快来联系吧 -->
          <hasoneToone />
        </div>
      </div>
    </div>
    <cardIsDialog></cardIsDialog>
  </div>
</template>

<script>
import cardIsDialog from "@/baseComponents/card/cardIsDialog";
import carouselBanner from "@/baseComponents/carouselBanner";
import filterSearch from "~hns/components/common/filterSearch";
// import commonPageBanner from "~hph/components/layouts/pageBanner";
import searchCompany from "~hns/components/company/unit/searchCompany";
import newPerson from "@/projects/henanSociety/components/company/unit/newPerson";
import hasoneToone from "@/projects/henanSociety/components/company/unit/hasoneToone";
import unitItem from "@/projects/henanSociety/components/company/unit/unitItem";
import isLoadingTip from "@/baseComponents/isLoadingTip";
import isAllDataTip from "@/baseComponents/isAllDataTip";
import companyAdvertisement from "../../components/company/unit/companyAdvertisement";

export default {
  components: {
    carouselBanner,
    searchCompany,
    newPerson,
    hasoneToone,
    unitItem,
    filterSearch,
    isLoadingTip,
    isAllDataTip,
    cardIsDialog,
    companyAdvertisement
  },
  metaInfo() {
    return {
      title: this.$t("companyList"),
    };
  },
  data() {
    return {
      total: 0,
      currentPage: 0,
      companyList: [],
      loading: false,
      queryParams: {
        companyName: "",
      },
      companyName: "",
      filters: {},
      cityType: 0,
    };
  },
  computed: {},
  created() {},
  watch: {
    "$route.query.parameter"() {
      if (this.$route.query.parameter) {
        this.queryParams.companyName = this._decode(
          this.$route.query.parameter
        ).searchVal;
      } else {
        this.queryParams.companyName = "";
      }
      this.currentPage = 0;
      this.getList();
    },
  },
  mounted() {
    if (this.$route.query.parameter) {
      this.queryParams.companyName = this._decode(
        this.$route.query.parameter
      ).searchVal;
    }
    this.getList(1);
    // window.addEventListener("scroll", this.scrollEvent, false);
  },
  // destroyed() {
  //   window.removeEventListener("scroll", this.scrollEvent, false);
  // },
  methods: {
    // 转数据
    handleData(obj) {
      let arr = [];
      for (let i in obj) {
        arr = arr.concat(obj[i]);
      }
      return arr;
    },
    handleChange(index) {
      this.currentPage = index - 1;
      this.getList();
    },
    async getList() {
      // window.removeEventListener("scroll", this.scrollEvent, false);
      //type 1是搜索赋值 2是下拉拼接
      this.loading = true;
      let params = {};
      params.start = this.currentPage;
      params.limit = 10;
      params.name = this.queryParams.companyName;
      params.city_id = this.filters.city;
      params.company_credit = this.filters.credit;
      params.genre_ids = this.filters.unitType;
      // params.certificate = 20;
      params.source = this.PJSource;
      params.user_id = this.USER_ID;
      // params.source_type = 1;
      params.is_vip = 1;
      let res = await this.$store.dispatch(
        "API_company/getCompanyList",
        params
      );
      if (res.success) {
        res.data.forEach((item, index) => {
          if (item.genre_ids.length > 1) {
            item.genre_ids = item.genre_ids.filter((ee) => ee != "1");
          }
        });
        // window.addEventListener("scroll", this.scrollEvent, false);
        res.data.forEach((item) => {
          item.business_classification_list = this.handleData(
            item.business_classification_info
          );
        });
        res.data.forEach((item) => {
          item.business_classification_list = this.handleData(
            item.business_classification_info
          );
        });
        this.companyList = res.data;
        this.total = res.total;
        this.loading = false;
        document.body.scrollIntoView();
        // if (type === 1) {
        //   this.companyList = res.data;
        // } else {
        //   this.companyList = this.companyList.concat(res.data);
        // }
      }
    },
    search() {
      this.currentPage = 0;
      this.getList();
    },
    handleCurrentChange() {},
    handleSearch(val) {
      this.filters = val;
      this.currentPage = 0;
      this.getList();
    },
    // scrollEvent() {
    //   if (
    //     document.documentElement.scrollTop +
    //       document.documentElement.clientHeight >=
    //     document.body.scrollHeight - 354
    //   ) {
    //     if (this.total != this.companyList.length) {
    //       this.getList(2);
    //     }
    //   }
    // },
    refresh() {
      this.currentPage = 0;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
.wrap {
  background: #fafafa;
}

.content_area {
  margin-bottom: 15px;
  .search-wrap {
    text-align: right;
    margin-bottom: 16px;
  }

  .main {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .left {
      margin-top: 20px;
      width: 896px;
      position: relative;
    }

    .right {
      margin-top: 20px;
      width: 280px;
    }
  }
}
</style>
